<template>
  <div class="mb10 do_payment_target">
    <h2 class="content_title">{{ $t('msg.ONIM099P010.016') }}</h2> <!-- 청구처 선택 -->
    <table class="tbl_row">
      <colgroup>
        <col width="14%">
        <col width="86%">
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('msg.ONIM099P010.017') }}</th> <!-- 청구처 -->
          <td>
            <input type="text" class="col_3" id="reqArea" v-model="businessInfo.bzrgNo"><span class="ml2"><a class="button sm" href="#" @click.prevent="openPopup('business-number-pop')">{{ $t('msg.ONEX010T010.010') }}</a></span>
          </td>
        </tr>
      </tbody>
    </table>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :business-info="businessInfo"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import doPayment from '@/api/rest/trans/do'
export default {
  name: 'FreightBillPoint',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'business-number-pop': () => import('@/pages/user/popup/BusinessNumberPop')
  },
  props: [],
  data: function () {
    return {
      customComponent: null,
      selectFunc: null,
      formData: {
        blNoArr: [],
        items: [],
        reqUsrNm: '', //신청인
        reqTelNo: '', //연락처
        uploadFileInfos: [],
        bsnNm: ''
      },
      businessInfo: {
        bzrgNo: '',
        bsnNm: ''
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created () {},
  mounted () {},
  methods: {
    openPopup (name) {
      this.customComponent = name
      // const txt = this.$_.trim(this.businessInfo.bzrgNo)
      // const re = new RegExp('^[0-9]+$', 'gi')
      // if (re.test(txt)) {
      //   this.businessInfo.bzrgNo = txt
      //   this.businessInfo.bsnNm = ''
      // } else {
      //   this.businessInfo.bzrgNo = ''
      //   this.businessInfo.bsnNm = txt
      // }
      this.$ekmtcCommon.layerOpen('.do_payment_target > .popup_dim')
    },
    async closePopup (obj) {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.do_payment_target > .popup_dim')
      this.businessInfo.bzrgNo = obj.bzrgNo
      this.getShipperDmdInfo(obj)
    },
    async getShipperDmdInfo (obj) {
        doPayment.getShipperDmdInfo(obj).then(response => {
           let dmdInfo = response.data
           if (!this.isEmpty(dmdInfo)) {
            obj.etDmdCatCd = dmdInfo.etDmdCatCd
            obj.imDmdCatCd = dmdInfo.imDmdCatCd
          }
          console.log(obj)
          this.$emit('child', { type: 'updateBillPoint', payload: { businessInfo: obj } })
        }).catch(e => {
          console.log(e)
        })
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    }
  }
}
</script>
<style>

</style>
