<template>
  <div class="mb10 do_payment_target" v-show="amtInfo.invAmt > 0">
    <h2 class="content_title">{{ $t('msg.PERS010L020.040') }}(KRW)</h2> <!-- 나의 신용한도 -->
    ※ 처리중인 건 포함하여 이용한도, 잔여한도가 표기 됩니다.
    <table class="tbl_row">
      <colgroup>
        <col width="30%">
        <col width="40%">
        <col width="30%">
      </colgroup>
      <tbody>
        <tr>
          <th>▶ 총 한도 :  {{ $ekmtcCommon.changeNumberFormat(amtInfo.totLimit, { isComma: true }) }}</th> <!-- 총 한도 -->
          <th>▶ 이용한도 : {{ $ekmtcCommon.changeNumberFormat(amtInfo.useLimit, { isComma: true }) }} <span v-if="amtInfo.tarAmt > 0">(처리중 : {{ $ekmtcCommon.changeNumberFormat(amtInfo.tarAmt, { isComma: true }) }})</span></th> <!-- 이용한도 -->
          <th>▶ 잔여한도 : {{ $ekmtcCommon.changeNumberFormat(amtInfo.rstLimit, { isComma: true, isMinus: true }) }}</th> <!-- 잔여한도 -->
        </tr>
        <tr>
          <td style="font-weight: bold;">▶ INVOICE(정산)금액 : {{ $ekmtcCommon.changeNumberFormat(amtInfo.invAmt, { isComma: true }) }}</td>
          <td colspan="2" style="color: red;font-weight: bold;">▶ 정산 후 잔여한도 : {{ $ekmtcCommon.changeNumberFormat(amtInfo.outAmt, { isComma: true, isMinus: true }) }} <span v-show="outMinus"> (※ 이용한도 부족)</span></td>
        </tr>
      </tbody>
    </table>
    • 총한도 - 이용한도(사용한 한도) = 잔여한도
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :business-info="businessInfo"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>
  <script>
  import CalcIssue from '@/api/rest/settle/calcIssue'
  export default {
    name: 'FreightBillCredit',
    components: {
      'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
      'business-number-pop': () => import('@/pages/user/popup/BusinessNumberPop')
    },
    props: ['blList'],
    data: function () {
      return {
        customComponent: null,
        amtInfo: {
          totLimit: 0,
          useLimit: 0,
          rstLimit: 0,
          invAmt: 0,
          outAmt: 0,
          tarAmt: 0
        },
        outMinus: false,
        creditOut: null
      }
    },
    computed: {},
    watch: {},
    created () {
      this.searchData()
    },
    mounted () {
    },
    methods: {
      searchData () {
        let blNo = []
        this.blList.forEach(bl => {
          blNo.push(bl.blNo)
        })
        CalcIssue.getCreditLimitInfo(blNo)
          .then(response => {
            console.log(response)
            const info = response.data
            this.amtInfo = info.amtInfo
            this.outMinus = Number(this.amtInfo.outAmt) < 0
            this.$emit('child', { type: 'creditOut', payload: { creditOut: this.amtInfo.outAmt } })
            //this.createChart(this.amtInfo)
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }

</script>
<style>
</style>
