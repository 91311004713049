var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.amtInfo.invAmt > 0,
          expression: "amtInfo.invAmt > 0",
        },
      ],
      staticClass: "mb10 do_payment_target",
    },
    [
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.PERS010L020.040")) + "(KRW)"),
      ]),
      _vm._v(" "),
      _vm._v(" ※ 처리중인 건 포함하여 이용한도, 잔여한도가 표기 됩니다. "),
      _c("table", { staticClass: "tbl_row" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [
              _vm._v(
                "▶ 총 한도 : " +
                  _vm._s(
                    _vm.$ekmtcCommon.changeNumberFormat(_vm.amtInfo.totLimit, {
                      isComma: true,
                    })
                  )
              ),
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "▶ 이용한도 : " +
                  _vm._s(
                    _vm.$ekmtcCommon.changeNumberFormat(_vm.amtInfo.useLimit, {
                      isComma: true,
                    })
                  ) +
                  " "
              ),
              _vm.amtInfo.tarAmt > 0
                ? _c("span", [
                    _vm._v(
                      "(처리중 : " +
                        _vm._s(
                          _vm.$ekmtcCommon.changeNumberFormat(
                            _vm.amtInfo.tarAmt,
                            { isComma: true }
                          )
                        ) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                "▶ 잔여한도 : " +
                  _vm._s(
                    _vm.$ekmtcCommon.changeNumberFormat(_vm.amtInfo.rstLimit, {
                      isComma: true,
                      isMinus: true,
                    })
                  )
              ),
            ]),
          ]),
          _c("tr", [
            _c("td", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(
                "▶ INVOICE(정산)금액 : " +
                  _vm._s(
                    _vm.$ekmtcCommon.changeNumberFormat(_vm.amtInfo.invAmt, {
                      isComma: true,
                    })
                  )
              ),
            ]),
            _c(
              "td",
              {
                staticStyle: { color: "red", "font-weight": "bold" },
                attrs: { colspan: "2" },
              },
              [
                _vm._v(
                  "▶ 정산 후 잔여한도 : " +
                    _vm._s(
                      _vm.$ekmtcCommon.changeNumberFormat(_vm.amtInfo.outAmt, {
                        isComma: true,
                        isMinus: true,
                      })
                    ) +
                    " "
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.outMinus,
                        expression: "outMinus",
                      },
                    ],
                  },
                  [_vm._v(" (※ 이용한도 부족)")]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" • 총한도 - 이용한도(사용한 한도) = 잔여한도 "),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "business-info": _vm.businessInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "40%" } }),
      _c("col", { attrs: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }