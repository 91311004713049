<template>
  <div class="popup_wrap" style="width:1000px; height: 600px;">
    <button class="layer_close" @click="close()" style="z-index: 1">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">
        <template v-if="freightBillInfo.btnType === 'FREIGHT'">{{ $t('msg.SETT010T010.013') }}</template> <!-- 운임수납 -->
        <template v-else-if="freightBillInfo.btnType === 'TAX_INVOICE'">{{ $t('msg.ONGO080G010.021') }}</template> <!-- 계산서 발행 -->
      </h1>

      <div class="content_box">
        <template v-if="freightBillInfo.btnType === 'TAX_INVOICE'">
          <h2 class="content_title">{{ $t('msg.ONEX070G040.013_1') }}</h2>
          <table class="tbl_row">
            <colgroup>
              <col width="170"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.ONEX070G040.020') }}</th> <!-- 신청인 -->
                <td>
                  <input type="text" id="reqUsrNm" name="reqUsrNm" v-model="taxBillReqInfo.reqUsrNm" class="col-6" maxlength="30">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G040.021') }}</th> <!-- 연락처 -->
                <td>
                  <input type="text" id="mbpOfcNo" name="mbpOfcNo" v-model="taxBillReqInfo.mbpOfcNo" class="col-6">
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G040.022') }}</th> <!-- 발행 지역 -->
                <td>
                  <select name="issPlcCd" id="issPlcCd" class="col-6" v-model="taxBillReqInfo.issPlcCd" @change.prevent="setEtcFlag">
                    <option value="">{{ $t('msg.ONEX070G040.069') }}</option> <!-- 선택-->
                    <option :id="plcCd.cd" v-for="(plcCd,index) in issPlcCdArr" :key="index" :value="plcCd.cd">{{ plcCd.cdNm }}</option>
                  </select>
                  <template>
                    <e-auto-complete-place v-if="etcFlag === 'Y'" @change="changePort" v-model="taxBillReqInfo.ctrCd" :placeholder="$t('msg.SPEC020P030.001')" ctr-cd="KOR" />
                  </template>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.ONEX070G040.023') }}</th> <!-- B/L No. -->
                <td>
                  {{ blNoStrings }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <FreightBillPoint
          ref="FBPoint"
          class="mt10"
          v-show="isShow.point"
          @child="getChildData"
        >
        </FreightBillPoint>
        <FreightBillDmdRadio
          ref="FBDmdRadio"
          class="mt10"
          v-show="isShow.dmdRadio"
          :dmd-cat-cd="freightBillInfo.dmdCatCd"
          :ori-dmd-cat-cd="oriDmdCatCd"
          :dp-id="freightBillInfo.dpId"
          @child="getChildData"
        >
        </FreightBillDmdRadio>
        <FreightBillInfo
          ref="FBInfo"
          v-show="isShow.info"
          :dp-id="freightBillInfo.dpId"
          :bl-list="freightBillInfo.blList"
          @child="getChildData"
        ></FreightBillInfo>
        <FreightBillMethod
          ref="FBMethod"
          v-show="isShow.method"
          :err-arr="errArr.method"
          :clear-arr="clearArr.method"
          :dp-id="freightBillInfo.dpId"
          :validation-method="validationMethod"
          :btn-type="freightBillInfo.btnType"
          @child="getChildData"
        ></FreightBillMethod>
        <FreightBillCredit
          ref="FBCredit"
          v-if="freightBillInfo.dmdCatCd === 'C'"
          :bl-list="freightBillInfo.blList"
          @child="getChildData"
        ></FreightBillCredit>
        <FreightBillTax
          ref="FBTax"
          v-show="isShow.taxTemplate"
          :err-arr="errArr.taxTemplate"
          :clear-arr="clearArr.taxTemplate"
          :dp-id="freightBillInfo.dpId"
          :issue-dt-info="issueDtInfo"
          :btn-type="freightBillInfo.btnType"
          :validation-tax-template="validationTaxTemplate"
          :bl-list="freightBillInfo.blList"
        ></FreightBillTax>
      </div>

      <div class="mt10 text_center">
        <a class="button blue lg" href="javascript:void(0)" @click="payment()">{{ $t('msg.SETT010T010.108') }}<!-- 결제 --></a>
        <a class="button gray lg" href="javascript:void(0)" @click="close()">{{ $t('msg.ONEX050P190.037') }}<!-- B/L No. --></a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import auth from '@/api/services/auth'
import CalcIssue from '@/api/rest/settle/calcIssue'
import FreightBillPoint from '@/components/common/FreightBillPoint'
import FreightBillInfo from '@/components/common/FreightBillInfo'
import FreightBillMethod from '@/components/common/FreightBillMethod'
import FreightBillTax from '@/components/common/FreightBillTax'
import FreightBillCredit from '@/components/common/FreightBillCredit'
import FreightBillDmdRadio from '@/components/common/FreightBillDmdRadio'
import { rootComputed } from '@/store/helpers'
let erp_popup = ''
export default {
  name: 'FreightBillPop',
  components: {
    FreightBillInfo,
    FreightBillMethod,
    FreightBillTax,
    FreightBillPoint,
    FreightBillDmdRadio,
    FreightBillCredit
  },
  props: [
    'freightBillInfo'
  ],
  data: function () {
    return {
      dummy: '',
      errArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      clearArr: {
        info: [],
        method: [],
        taxTemplate: []
      },
      isShow: {
        info: true,
        method: true,
        taxTemplate: true,
        point: false,
        dmdRadio: true
      },
      issueDtInfo: {
        isFast: false, //입금일이 온보드일보다 빠른지
        isUse: false, //해당기능 사용할지
        depositDt: '',
        obrdDt: ''
      },
      taxBillReqInfo: {
        reqUsrNm: '',
        mbpOfcNo: '',
        issPlcCd: '',
        ctrCd: ''
      },
      etcFlag: 'N',
      issPlcCdArr: [],
      blNoStrings: '',
      isCredit: false,
      oriDmdCatCd: null,
      isInit: false,
      type: '',
      lang: '',
      creditOut: 0
    }
  },
  computed: {
     ...rootComputed
  },
  watch: {
    freightBillInfo: {
      deep: true,
      handler () {
        this.getIsShow()
      }
    },
    'isShow.taxTemplate' (val) {
      // 세금계산서 항목이 숨겨질때 값을 초기화
      if (!val) {
        this.$refs.FBTax.reset()
      }
    }
  },
  created () {
  },
  mounted: function () {
    setTimeout(() => {
      this.isInit = false
      this.getIsShow()

      if (this.freightBillInfo.btnType === 'TAX_INVOICE') {
        if (this.auth !== undefined) {
          this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
          this.taxBillReqInfo.reqUsrNm = this.auth.userName
        }
        this.setInitInfo()
      }
    }, 300)
  },
  methods: {
    getChildData (data) {
      switch (data.type) {
        case 'updateDepoistDt':
          this.calcIssueDtInfo(data.payload.depositInfoArr)
          break
        case 'updateBillPoint':
          this.$refs.FBTax.setBusinessInfo(data.payload.businessInfo)
          this.freightBillInfo.dmdCatCd = this.freightBillInfo.eiCatCd === 'I' ? data.payload.businessInfo.imDmdCatCd : data.payload.businessInfo.etDmdCatCd
          setTimeout(() => {
            this.oriDmdCatCd = this.freightBillInfo.dmdCatCd
          }, 300)
          this.getIsShow()
          break
        case 'updateSelectDmdCatCd':
          this.freightBillInfo.dmdCatCd = data.payload.selectDmdCatCd
          this.getIsShow()
          break
        case 'creditOut':
          console.log('data.payload.creditOut=======================>', data.payload.creditOut)
          this.creditOut = data.payload.creditOut
          break
      }
    },
    setNationalType (lang) {
      if (lang === 'KOR') {
        this.type = 'KO'
      } else {
        if (this.memberDetail !== undefined) {
          if (this.memberDetail.userCtrCd === 'TH') {
            this.type = 'TH'
          } else if (this.memberDetail.userCtrCd === 'JP') {
            this.type = 'JP'
          } else {
            this.type = 'EN'
          }
        }
      }
    },
    async setInitInfo () {
      this.setNationalType(this.lang)
      //국가별 issPlcCd 셋팅
      if (this.type === 'KO') {
        this.issPlcCdArr = [
          { cd: 'SEL', cdNm: this.$t('msg.ONEX070G040.032') }, //서울
          { cd: 'INC', cdNm: this.$t('msg.ONEX070G040.033') }, //인천
          { cd: 'PUS', cdNm: this.$t('msg.ONEX070G040.034') }, //부산
          { cd: 'USN', cdNm: this.$t('msg.ONEX070G040.035') }, //울산
          { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
        ]
      } else if (this.type === 'TH') {
        this.issPlcCdArr = [
          { cd: 'BKK', cdNm: this.$t('msg.ONEX070G040.037') }, //BANGKOK
          { cd: 'LCH', cdNm: this.$t('msg.ONEX070G040.038') }, //LAEM CHABANG
          { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
        ]
      } else if (this.type === 'JP') {
        this.issPlcCdArr = [
          { cd: 'OSA', cdNm: this.$t('msg.ONEX070G040.039') }, //OSAKA
          { cd: 'TYO', cdNm: this.$t('msg.ONEX070G040.040') }, //TOKYO
          { cd: 'ETC', cdNm: this.$t('msg.ONEX070G040.036') } //국외
        ]
      }

      let resultArr = []
      this.freightBillInfo.blList.map(b => {
        if (!resultArr.includes(b.blNo)) {
          resultArr.push(b.blNo)
        }
      })

      this.blNoStrings = resultArr.toString()

      await auth.info(this.auth.jwt).then(res => {
        const obj = res.data
        let usrTel = ''

        if (this.$ekmtcCommon.isNotEmpty(obj)) {
              if (this.$ekmtcCommon.isNotEmpty(obj.cel1)) {
            usrTel += obj.cel1
          }
          if (this.$ekmtcCommon.isNotEmpty(obj.cel2)) {
            usrTel += obj.cel2
          }
          if (this.$ekmtcCommon.isNotEmpty(obj.cel3)) {
            usrTel += obj.cel3
          }
          if (this.$ekmtcCommon.isNotEmpty(obj.cel4)) {
            usrTel += obj.cel4
          }

          this.taxBillReqInfo.mbpOfcNo = usrTel
        }
      })
    },
    calcIssueDtInfo (depositInfoArr) {
      // 입금일, 온보드일자 변경과 관계없이 계산서발행일자는 미래날짜만 선택 불가능하도록 변경하기 위해서 아래 조건은 주석처리 2022.02.18

      // let issueDtInfo = {}
      // issueDtInfo.isUse = false
      // if (!this.isEmpty(this.freightBillInfo.blList)) {
      //   let depositDt = 0
      //   for (let d of depositInfoArr) {
      //     if (!this.isEmpty(d.depositDt)) {
      //       if (depositDt < parseInt(d.depositDt)) {
      //         depositDt = parseInt(d.depositDt)
      //       }
      //     }
      //   }
      //   let obrdDt = 0
      //   for (let bl of this.freightBillInfo.blList) {
      //     if (!this.isEmpty(bl.obrdDt)) {
      //       if (obrdDt < parseInt(bl.obrdDt)) {
      //         obrdDt = parseInt(bl.obrdDt)
      //       }
      //     }
      //   }
      //   issueDtInfo.depositDt = depositDt
      //   issueDtInfo.obrdDt = obrdDt
      //   issueDtInfo.isFast = issueDtInfo.depositDt < issueDtInfo.obrdDt
      //   if (obrdDt !== 0 && depositDt !== 0) {
      //     issueDtInfo.isUse = true
      //      this.issueDtInfo = issueDtInfo
      //   }
      // }
    },
    payment () {
      let taxTemplate = this.$refs.FBTax.emitData('taxTemplate')
      let method = this.$refs.FBMethod.emitData('method')
      let info = this.$refs.FBInfo.emitData('info')

      if (this.freightBillInfo.dmdCatCd === 'C') {
        if (this.$ekmtcCommon.isNotEmpty(this.creditOut) && Number(this.creditOut) < 0) {
          this.msgAlert('외상한도 초과 또는 외상기한이 만료되었습니다.\n담당 영업사원 확인 또는 외상정리 후 신청 바랍니다.')
          return
        }
      }
      // 추가내용이 있는 경우 요청내용에 추가한다.
      if (taxTemplate && taxTemplate.reqAddCont) {
        taxTemplate.reqCont = taxTemplate.reqAddCont + '\r\n' + taxTemplate.reqCont
      }

      // if (this.validationMethod(method, 'payBtn') && this.validationTaxTemplate(taxTemplate, 'payBtn')) {
      if (this.validationInfoMethod(info, method, 'payBtn') && this.validationTaxTemplate(taxTemplate, 'payBtn')) {
        let req = this.parseReqData(taxTemplate, method, info)
        console.log(req)
        if (this.freightBillInfo.btnType === 'TAX_INVOICE') {
          let blList = ''

          // if (this.freightBillInfo.dpId === 'CREDIT') {
          //   blList = this.freightBillInfo.invNo
          // } else {
            for (let i = 0; i < req.blList.length; i++) {
              if (i > 0) {
                blList += ','
              }
              blList += req.blList[i].blNo
            }
          // }
          CalcIssue.checkHasCm134I(blList).then(response => {
            if (response.data.errCode === '2000') {
              if (this.freightBillInfo.dpId === 'CREDIT') {
                this.bankingInfoAndCreditTaxRequest(req)
              } else {
                if (method.payType === 'D') {
                  if (this.freightBillInfo.btnType === 'DO') {
                    this.bankingInfoAndDoRequest(req)
                  } else {
                    this.bankingInfoAndBlRequest(req)
                  }
                } else {
                  this.pay(req, method, info)
                }
              }
            } else {
              this.msgAlert('세금계산서 발급 진행중')
            }
          }).catch(e => {
          this.msgAlert(this.$t('art.CSBL220.015')) // 결제에 실패하였습니다.
            console.log(e)
          })
        } else {
          if (method.payType === 'D' && this.isShow.method) {
            if (this.freightBillInfo.btnType === 'DO') {
              this.bankingInfoAndDoRequest(req)
            } else {
              this.bankingInfoAndBlRequest(req)
            }
          } else {
            this.pay(req, method, info)
          }
        }
      }
    },
    bankingInfoAndDoRequest (req) {
      CalcIssue.bankingInfoAndDoRequest(req).then(response => {
        if (response.data.errCode === '2000') {
          this.msgAlert('세금계산서 요청하였습니다.')
          this.close()
        } else if (response.data.errCode === '9992') {
          this.msgAlert(this.$t('art.CSBL220.009')) // '이미 발행 되었습니다.'
        } else if (response.data.errCode === '9993') {
          this.msgAlert(this.$t('art.CSBL220.010')) // '이미 승인 되었습니다.'
        } else {
          this.msgAlert('세금계산서 요청 실패하였습니다.')
        }
      }).catch(e => {
        this.msgAlert('세금계산서 요청 실패하였습니다.')
        console.log(e)
      })
    },
    bankingInfoAndBlRequest (req) {
      CalcIssue.bankingInfoAndBlRequest(req).then(response => {
        if (response.data.errCode === '2000') {
          this.msgAlert('세금계산서 요청하였습니다.')
          this.close()
        } else if (response.data.errCode === '9992') {
          this.msgAlert(this.$t('art.CSBL220.009')) // '이미 발행 되었습니다.'
        } else if (response.data.errCode === '9993') {
          this.msgAlert(this.$t('art.CSBL220.010')) // '이미 승인 되었습니다.'
        } else {
          this.msgAlert('세금계산서 요청 실패하였습니다.')
        }
      }).catch(e => {
        this.msgAlert('세금계산서 요청 실패하였습니다.')
        console.log(e)
      })
    },
    pay (req, method, info) {
      if (this.freightBillInfo.btnType === 'TAX_INVOICE') {
        req.pageType = 'TAX_BILL'
      }

      CalcIssue.pay(req).then(response => {
        console.log(response)
          if (req.type === 'N') {
            this.fnErpSave(response.data.acNo, response.data.erpReqNo, 3, method, info)
          } else {
            this.msgAlert('결제되었습니다.')
            this.close()
          }
      }).catch(e => {
        this.msgAlert(this.$t('art.CSBL220.015')) // '결제에 실패하였습니다.'
        //this.cancelPay(response.data.acNo)
        console.log(e)
      })
    },
    parseReqData (taxTemplate, method, info) {
      let res = {}
      let vrAccNoList = []

      const dmdCatCd = this.$refs.FBDmdRadio.selectDmdCatCd

      if (method.payType === 'D') {
        let blNo = this.splitBlNo(info.blList)
        res = {
          reqBizCatCd: '14',
          creditYn: this.isCredit,
          asBlNo: blNo,
          blNo: blNo,
          payAcctList: method.depositInfoArr,
          taxTemplate: taxTemplate,
          type: 'insertBankInfo',
          //
          blList: info.blList,
          csbl220requestVO: {},
          amtSetList: [],
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          currencyCodeList: ['KRW', 'USD'],
          obrdDtList: [],
          corpNameList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220accVO: {},
          file: taxTemplate.file,
          overseasFileList: this.isEmpty(method.overseasFileList) ? [] : method.overseasFileList
        }
      } else if (method.payType === 'V') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        if (vrAccNoList.length === 0) {
          this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
          return false
        }
        res = {
          reqBizCatCd: '14',
          type: method.payType,
          creditYn: this.isCredit,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          file: taxTemplate.file
        }
      } else if (method.payType === 'N') {
        if (!this.isEmpty(method.vrAccount.krw)) {
          vrAccNoList.push(method.vrAccount.krw)
        }
        if (!this.isEmpty(method.vrAccount.usd)) {
          vrAccNoList.push(method.vrAccount.usd)
        }
        res = {
          reqBizCatCd: '14',
          type: method.payType,
          creditYn: this.isCredit,
          totKrwAmt: info.krwTotal,
          totUsdAmt: info.usdTotal,
          blList: info.blList,
          currencyCodeList: ['KRW', 'USD'],
          vrAcctNoList: vrAccNoList,
          obrdDtList: [],
          corpNameList: [],
          amtSetList: [],
          csdo210vo: {},
          csbl220frVO: taxTemplate,
          csbl220requestVO: {},
          csbl220accVO: {},
          erpBankCode: method.bankLoginInfo.bnkAcct,
          erpBankName: '',
          file: taxTemplate.file
        }
      }

      if (this.freightBillInfo.dpId === 'CREDIT') {
        res.invNo = this.freightBillInfo.invNo
      }

      const taxReqInfo = this.taxBillReqInfo

      res.csbl220requestVO = {
        reqPicNm: taxReqInfo.reqUsrNm,
        mbpOfcNo: taxReqInfo.mbpOfcNo,
        issPlcCd: taxReqInfo.issPlcCd
      }

      res.payType = method.payType
      res.dmdCatCd = dmdCatCd

      return res
    },
    splitBlNo (blList) {
      let blNo = ''
      let isFirst = true
      for (let bl of blList) {
        if (!isFirst) {
          blNo += ','
        }
        blNo += bl.blNo
        isFirst = false
      }
      return blNo
    },
    fnErpSave (reqAcNo, erpKmtcNo, cnt, method, info) {
      let data = {
        userId: method.bankLoginInfo.bnkId,
        userPw: method.bankLoginInfo.bnkPswd,
        acNo: method.bankLoginInfo.bnkAcct,
        mnrcvBnkCd: '088',
        mnrcvAcNo: '26204000149',
        amt: info.krwTotal,
        inptDraweName: reqAcNo,
        AcnoMemo: '',
        erpKmtcNo: erpKmtcNo
      }
      CalcIssue.tenUnderTransfer(data).then(response => {
          let resp = response.data
          if (resp.rtnCode !== '0000') {
            this.msgAlert(resp.rtnMsg)
          } else {
            if (!this.isEmpty(resp.outRecDto) && !this.isEmpty(resp.outRecDto.rtnCode) && !this.isEmpty(resp.outRecDto.rtnCode)) {
              this.cancelPay('N', acNo)
            } else {
              //openPopup
              this.openErpPopup(cnt, response, data, reqAcNo)
            }
          }
      }).catch(e => {
        this.msgAlert(this.$t('msg.CSBL220.015')) // '결제에 실패하였습니다.'
        this.cancelPay('N', reqAcNo)
        console.log(e)
      })
    },
    openErpPopup (cnt, response, data, reqAcNo) {
      if (cnt === 1) {
        let url = 'https://cms.kbstar.com/quics?page=C066733'
        let name = this.$t('msg.SETT010T010.084') // '즉시 이체'
        let option = 'width = 880, height = 700, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      } else {
        let url = 'https://cms.kbstar.com/quics?page=C102879'
        let name = '간편 이체'
        let option = 'width = 360, height = 460, top = 100, left = 500, location = no,scrollbars=yes'
        erp_popup = window.open(url, name, option)
      }
      this.chk_popup(response, data, reqAcNo)
    },
    chk_popup (response, data) {
      if (typeof (erp_popup) === 'undefined' || erp_popup.closed) {
        this.erpResult(response, data, reqAcNo)
      } else {
        setTimeout(() => {
          this.chk_popup(response, data)
        }, 3000)
      }
    },
    cancelPay (type, acNo) {
      CalcIssue.cancelPay({ type: type, acNo: acNo }).then(response => {
        console.log(response)
      }).catch(e => {
        console.log(e)
      })
    },
    erpResult (response, data, reqAcNo) {
      let req = {
        userId: data.userId,
        userPw: data.userPw,
        erpregno: response.erpregno,
        erpAccNo: data.acNo
      }
      CalcIssue.tenUnderSel(req).then(response => {
        if (response.PrcssFnshYn === 'Y') {
          this.fnErpRegNoEdit(response.ERPRegiPrcssNo, reqAcNo)
        } else {
          //
        }
      }).catch(e => {
        console.log(e)
      })
    },
    fnErpRegNoEdit (erpNo, acNo) {
      let param = {
        acNo: acNo,
        erpNo: erpNo
      }
      let blList = [] //TODO get blList
      let linkBlNo = '' // ex ) a,b,c,d
      CalcIssue.fnErpRegNoEdit(param).then(response => {
         if (response.data.errCode === '2000') {
          this.msgAlert('결제되었습니다.')
          this.close()
        } else {
          this.msgAlert('..?')
        }
      }).catch(e => {
        console.log(e)
      })
    },
    validationMethod (method, type) {
       let errArr = []
       let clearArr = []
       if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
         if (method.payType === 'D') {
           for (let row of method.depositInfoArr) {
             if (this.isEmpty(row.payCur)) {
               errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: this.$t('msg.CSBL220.017') }) // 화폐를 선택해주세요.
             } else {
               clearArr.push('fbt-payCur-' + row.rowIdx)
             }

             if (this.isEmpty(row.bank)) {
               errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: this.$t('msg.CSBL220.018') }) // 은행을 선택해주세요.
             } else {
               clearArr.push('fbt-bank-' + row.rowIdx)
             }

             if (this.isEmpty(row.accNo)) {
               errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: this.$t('msg.CSBL220.019') }) // 계좌번호를 선택해주세요.
             } else {
               clearArr.push('fbt-accNo-' + row.rowIdx)
             }
             if (this.isEmpty(row.depositPnm)) {
               errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: this.$t('msg.CSBL220.020') }) // 입금자를 입력해주세요.
             } else {
               clearArr.push('fbt-depositPnm-' + row.rowIdx)
             }

             if (this.isEmpty(row.depositAmt)) {
               errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: this.$t('msg.CSBL220.021') }) // 금액을 입력해주세요.
             } else {
               clearArr.push('fbt-depositAmt-' + row.rowIdx)
             }

             if (this.isEmpty(row.depositDt)) {
               errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: this.$t('msg.CSBL220.022') }) // 입금일을 입력해주세요.
             } else {
               clearArr.push('fbt-depositDt-' + row.rowIdx)
             }
           }
         } else if (method.payType === 'V') {
          //
         } else if (method.payType === 'N') {
          //
         }

         this.clearArr.method = clearArr
         if (errArr.length === 0) {
           return true
         } else {
           if (type === 'payBtn') {
             this.errArr.method = errArr
           }
           return false
         }
       } else {
         return true
       }
      //return true
    },
    //세금계산서 결제정보, 결제수단 선택 밸리데이션
    validationInfoMethod (info, method, type) {
      let errArr = []
      let clearArr = []
     if (this.isShow.method && this.freightBillInfo.dmdCatCd === 'R') {
        if (method.payType === 'D') {
          for (let row of method.depositInfoArr) {
            if (this.isEmpty(row.payCur)) {
              errArr.push({ id: 'fbt-payCur-' + row.rowIdx, msg: this.$t('msg.CSBL220.017') }) // 화폐를 선택해주세요.
            } else {
              clearArr.push('fbt-payCur-' + row.rowIdx)
            }

            if (this.isEmpty(row.bank)) {
              errArr.push({ id: 'fbt-bank-' + row.rowIdx, msg: this.$t('msg.CSBL220.018') }) // 은행을 선택해주세요.
            } else {
              clearArr.push('fbt-bank-' + row.rowIdx)
            }

            if (this.isEmpty(row.accNo)) {
              errArr.push({ id: 'fbt-accNo-' + row.rowIdx, msg: this.$t('msg.CSBL220.019') }) // 계좌번호를 선택해주세요.
            } else {
              clearArr.push('fbt-accNo-' + row.rowIdx)
            }
            if (this.isEmpty(row.depositPnm)) {
              errArr.push({ id: 'fbt-depositPnm-' + row.rowIdx, msg: this.$t('msg.CSBL220.020') }) // 입금자를 입력해주세요.
            } else {
              clearArr.push('fbt-depositPnm-' + row.rowIdx)
            }

            if (this.isEmpty(row.depositAmt)) {
              errArr.push({ id: 'fbt-depositAmt-' + row.rowIdx, msg: this.$t('msg.CSBL220.021') }) // 금액을 입력해주세요.
            } else {
              clearArr.push('fbt-depositAmt-' + row.rowIdx)
            }

            if (this.isEmpty(row.depositDt)) {
              errArr.push({ id: 'fbt-depositDt-' + row.rowIdx, msg: this.$t('msg.CSBL220.022') }) // 입금일을 입력해주세요.
            } else {
              clearArr.push('fbt-depositDt-' + row.rowIdx)
            }
          }
        } else if (method.payType === 'V') {
          //
          let isExistKrw = false
          let isExistKrwUsd = false

          if (this.isEmpty(method.vrAccount.krw) && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (info.blList !== null && info.blList.length > 0) {
            for (let i = 0; i < info.blList.length; i++) {
              if (info.blList[i].currency === 'KRW') {
                isExistKrw = true
              } else if (info.blList[i].currency === 'KRW+USD') {
                isExistKrw = true
                isExistKrwUsd = true
              }
            }
          }

          if (isExistKrw && this.isEmpty(method.vrAccount.krw)) {
            this.msgAlert('KRW 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }

          if (isExistKrwUsd && this.isEmpty(method.vrAccount.usd)) {
            this.msgAlert('USD 가상계좌가 존재하지 않습니다. <br/> 가상계좌를 생성해주세요.')
            return false
          }
        } else if (method.payType === 'N') {
          //
          if (info.usdTotal > 0) {
            this.msgAlert('즉시이체는 KRW 결제만 가능합니다.')
            return false
          }
        }

        this.clearArr.method = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.method = errArr
          }
          return false
        }
      }

      return true
    },
    validationTaxTemplate (taxTemplate, type) {
      let errArr = []
      let clearArr = []
      if (this.isShow.taxTemplate) {
        if (this.isEmpty(taxTemplate.rcivNm)) {
          errArr.push({ id: 'fbt-rcivNm', msg: this.$t('msg.ONIM050G020.017') }) // 상호를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivNm')
        }
        if (this.isEmpty(taxTemplate.rcivAddr)) {
          errArr.push({ id: 'fbt-rcivAddr', msg: this.$t('msg.ONIM050G020.017') }) //주소를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivAddr')
        }
        if (this.isEmpty(taxTemplate.rcivBzrgNo)) {
          errArr.push({ id: 'fbt-rcivBzrgNo', msg: this.$t('msg.ONIM050G020.035') }) // 사업자 번호를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivBzrgNo')
        }
        // if (this.isEmpty(taxTemplate.file)) {
        //   errArr.push({ id: 'fbt-file', msg: '사업자 등록증을 첨부해주세요.' })
        // } else {
        //   clearArr.push('fbt-file')
        // }
        if (this.isEmpty(taxTemplate.emlAddr)) {
          errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.ONIM050G020.018') }) // email을 입력해주세요.
        } else {
          // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
          let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (taxTemplate.emlAddr.match(regExp) == null) {
            errArr.push({ id: 'fbt-emlAddr', msg: this.$t('msg.REPORT_PDF.111') }) // 이메일 형식을 확인해 주세요.
          } else {
            clearArr.push('fbt-emlAddr')
          }
        }
        if (this.isEmpty(taxTemplate.rcivPicNm)) {
          errArr.push({ id: 'fbt-rcivPicNm', msg: this.$t('msg.CSBL270.015') }) // 담당자를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivPicNm')
        }
        if (this.isEmpty(taxTemplate.rcivCtfc)) {
          errArr.push({ id: 'fbt-rcivCtfc', msg: this.$t('msg.CSBL270.003') }) // 연락처를 입력해주세요.
        } else {
          clearArr.push('fbt-rcivCtfc')
        }

        if (taxTemplate.acshIssKind === '02' && this.isEmpty(taxTemplate.acshIssDt)) {
          errArr.push({ id: 'fbt-acshIssDt', msg: this.$t('msg.ONIM050G020.033') }) // 계산서 발행일을 입력해주세요.
        } else {
          clearArr.push('fbt-acshIssDt')
        }

        this.clearArr.taxTemplate = clearArr
        if (errArr.length === 0) {
          return true
        } else {
          if (type === 'payBtn') {
            this.errArr.taxTemplate = errArr
          }
          return false
        }
      } else {
        return true
      }
    },
    close () {
      this.$emit('close')
      this.$refs.FBMethod.reset()
      this.$refs.FBTax.reset()
      this.isInit = false
    },
    msgAlert (msg) {
       let obj = {
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.ONEX070G050.007'), // 닫기,
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getIsShow () {
      if (!this.isInit) {
        if (!this.isEmpty(this.freightBillInfo.dmdCatCd)) {
          this.oriDmdCatCd = JSON.parse(JSON.stringify(this.freightBillInfo.dmdCatCd))
          this.isInit = true
        }
      }
      let isShow = {
        info: true,
        method: true,
        taxTemplate: true,
        point: true,
        dmdRadio: true
      }
      if (this.freightBillInfo.btnType !== 'FREIGHT') {
        // 기존에 월말청구화주일 경우에만 creditYn이 Y인 것을 청구화주일 경우에도 creditYn이 Y가 되도록 변경
        if (this.freightBillInfo.dmdCatCd === 'C' || this.freightBillInfo.dmdCatCd === 'M') {
          this.isCredit = 'Y'
          isShow.method = false
        } else {
          this.isCredit = 'N'
          isShow.method = true
        }
      }
      switch (this.freightBillInfo.btnType) {
        case 'FREIGHT':
          isShow.dmdRadio = false
          isShow.point = false
          break
        case 'TAX_INVOICE':
          if (this.freightBillInfo.dmdCatCd === 'R') { //영수화주
            if (!this.freightBillInfo.hasNotPaid) {
              isShow.info = false
              isShow.taxTemplate = false
            }
          } else if (this.freightBillInfo.dmdCatCd === 'C') { //청구화주
            isShow.method = false
          } else if (this.freightBillInfo.dmdCatCd === 'M') { //월말화주
            // isShow.info = false
            // isShow.method = false
            // isShow.taxTemplate = false
             isShow.method = false
          }

          if (this.freightBillInfo.eiCatCd === 'I') {
            isShow.point = true
          } else {
            isShow.point = false
          }

          break
      }
      this.isShow = isShow
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    bankingInfoAndCreditTaxRequest (req) {
      CalcIssue.bankingInfoAndCreditTaxRequest(req).then(response => {
        if (response.data.errCode === '2000') {
          this.msgAlert('세금계산서 요청하였습니다.')
          this.close()
        } else if (response.data.errCode === '9992') {
          this.msgAlert(this.$t('art.CSBL220.009')) // '이미 발행 되었습니다.'
        } else if (response.data.errCode === '9993') {
          this.msgAlert(this.$t('art.CSBL220.010')) // '이미 승인 되었습니다.'
        } else {
          this.msgAlert('세금계산서 요청 실패하였습니다.')
        }
      }).catch(e => {
        this.msgAlert('세금계산서 요청 실패하였습니다.')
        console.log(e)
      })
    }
  }
}
</script>
