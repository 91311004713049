var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb10 do_payment_target" },
    [
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONIM099P010.016"))),
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "tbl_row" }, [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM099P010.017")))]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.businessInfo.bzrgNo,
                    expression: "businessInfo.bzrgNo",
                  },
                ],
                staticClass: "col_3",
                attrs: { type: "text", id: "reqArea" },
                domProps: { value: _vm.businessInfo.bzrgNo },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.businessInfo, "bzrgNo", $event.target.value)
                  },
                },
              }),
              _c("span", { staticClass: "ml2" }, [
                _c(
                  "a",
                  {
                    staticClass: "button sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openPopup("business-number-pop")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010T010.010")))]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "business-info": _vm.businessInfo },
                on: { close: _vm.closePopup },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "86%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }