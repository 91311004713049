var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1000px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          staticStyle: { "z-index": "1" },
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c(
          "h1",
          { staticClass: "page_title" },
          [
            _vm.freightBillInfo.btnType === "FREIGHT"
              ? [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.013")))]
              : _vm.freightBillInfo.btnType === "TAX_INVOICE"
              ? [_vm._v(_vm._s(_vm.$t("msg.ONGO080G010.021")))]
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "content_box" },
          [
            _vm.freightBillInfo.btnType === "TAX_INVOICE"
              ? [
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.013_1"))),
                  ]),
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.020"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.taxBillReqInfo.reqUsrNm,
                                expression: "taxBillReqInfo.reqUsrNm",
                              },
                            ],
                            staticClass: "col-6",
                            attrs: {
                              type: "text",
                              id: "reqUsrNm",
                              name: "reqUsrNm",
                              maxlength: "30",
                            },
                            domProps: { value: _vm.taxBillReqInfo.reqUsrNm },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.taxBillReqInfo,
                                  "reqUsrNm",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.021"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.taxBillReqInfo.mbpOfcNo,
                                expression: "taxBillReqInfo.mbpOfcNo",
                              },
                            ],
                            staticClass: "col-6",
                            attrs: {
                              type: "text",
                              id: "mbpOfcNo",
                              name: "mbpOfcNo",
                            },
                            domProps: { value: _vm.taxBillReqInfo.mbpOfcNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.taxBillReqInfo,
                                  "mbpOfcNo",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.022"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxBillReqInfo.issPlcCd,
                                    expression: "taxBillReqInfo.issPlcCd",
                                  },
                                ],
                                staticClass: "col-6",
                                attrs: { name: "issPlcCd", id: "issPlcCd" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.taxBillReqInfo,
                                        "issPlcCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      $event.preventDefault()
                                      return _vm.setEtcFlag.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.069"))),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.issPlcCdArr,
                                  function (plcCd, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        attrs: { id: plcCd.cd },
                                        domProps: { value: plcCd.cd },
                                      },
                                      [_vm._v(_vm._s(plcCd.cdNm))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                            [
                              _vm.etcFlag === "Y"
                                ? _c("e-auto-complete-place", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "msg.SPEC020P030.001"
                                      ),
                                      "ctr-cd": "KOR",
                                    },
                                    on: { change: _vm.changePort },
                                    model: {
                                      value: _vm.taxBillReqInfo.ctrCd,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.taxBillReqInfo,
                                          "ctrCd",
                                          $$v
                                        )
                                      },
                                      expression: "taxBillReqInfo.ctrCd",
                                    },
                                  })
                                : _vm._e(),
                            ],
                          ],
                          2
                        ),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.023"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(" " + _vm._s(_vm.blNoStrings) + " ")]),
                      ]),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _c("FreightBillPoint", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.point,
                  expression: "isShow.point",
                },
              ],
              ref: "FBPoint",
              staticClass: "mt10",
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillDmdRadio", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.dmdRadio,
                  expression: "isShow.dmdRadio",
                },
              ],
              ref: "FBDmdRadio",
              staticClass: "mt10",
              attrs: {
                "dmd-cat-cd": _vm.freightBillInfo.dmdCatCd,
                "ori-dmd-cat-cd": _vm.oriDmdCatCd,
                "dp-id": _vm.freightBillInfo.dpId,
              },
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillInfo", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.info,
                  expression: "isShow.info",
                },
              ],
              ref: "FBInfo",
              attrs: {
                "dp-id": _vm.freightBillInfo.dpId,
                "bl-list": _vm.freightBillInfo.blList,
              },
              on: { child: _vm.getChildData },
            }),
            _c("FreightBillMethod", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.method,
                  expression: "isShow.method",
                },
              ],
              ref: "FBMethod",
              attrs: {
                "err-arr": _vm.errArr.method,
                "clear-arr": _vm.clearArr.method,
                "dp-id": _vm.freightBillInfo.dpId,
                "validation-method": _vm.validationMethod,
                "btn-type": _vm.freightBillInfo.btnType,
              },
              on: { child: _vm.getChildData },
            }),
            _vm.freightBillInfo.dmdCatCd === "C"
              ? _c("FreightBillCredit", {
                  ref: "FBCredit",
                  attrs: { "bl-list": _vm.freightBillInfo.blList },
                  on: { child: _vm.getChildData },
                })
              : _vm._e(),
            _c("FreightBillTax", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow.taxTemplate,
                  expression: "isShow.taxTemplate",
                },
              ],
              ref: "FBTax",
              attrs: {
                "err-arr": _vm.errArr.taxTemplate,
                "clear-arr": _vm.clearArr.taxTemplate,
                "dp-id": _vm.freightBillInfo.dpId,
                "issue-dt-info": _vm.issueDtInfo,
                "btn-type": _vm.freightBillInfo.btnType,
                "validation-tax-template": _vm.validationTaxTemplate,
                "bl-list": _vm.freightBillInfo.blList,
              },
            }),
          ],
          2
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.payment()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.108")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050P190.037")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "170" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }